.container_avatar {
    width: 35px;
    height: 35px;
    border-radius: 40px;
    overflow: hidden;
    margin: 0 10px;
}

.image_avatar {
    width: 100%;
    height: 100%;
}
