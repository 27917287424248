.Select {
    min-height: 35px;
    width: 100%;
}



.Select .react-select__control:hover {
    border: 0px solid #eff3f4;
    border-bottom: 2px solid #a9afb5;
}



.input-required .is-invalid .react-select__control {
    border: 2px solid #c7323c !important;
}

.cardCrud .Select.is-invalid .react-select__control {
    border-bottom: 2px solid #c7323c !important;
}

.cardCrud .Select.is-invalid .react-select__control {
    border-bottom: 2px solid #c7323c;
}

.cardCrud .Select.is-invalid .react-select__control .css-1uccc91-singleValue {
    color: #c7323c;
}

.cardCrud .input-required label {
    font-weight: bold;
}