.containerSumaryReceive {
    background-color: #63b37e;
    border-radius: 0;
    width: 100%;
    height: 65px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
}

.iconBackground_sumaryReceive {
    background-color: transparent;
    border: none;
}

.labelSumaryReceive {
    color: #fff;
    font-size: 16px;
    /* font-family: 'Montserrat-Regular'; Uncomment and update as needed */
    letter-spacing: 0.008em;
    padding-left: 20px;
    padding-right: 20px;
}

.labelValueContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.valueSumaryReceive {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.008em;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 5px;
}

.iconContainerSumaryReceive {
    position: relative;
    padding-right: 10px;
}

.iconBackgroundSumaryReceive {
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
}

.icon {
    width: 21px;
    height: 21px;
    opacity: 0.6;
}