.header-top {
    background-color: #fff;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 25px 20px;
    flex-wrap: wrap;
    position: fixed;
    box-shadow: 0px 0px 7px #00000052;
    top: 0;
    left: 0;
    z-index: 1049;

}


.logoContainer_Header {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

}

.ico-menu {
    flex: 1;
}

.container_menuPerfil {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.container_menu {
    flex: 1;
    display: flex;
    align-items: center;
    transition: all 400ms;
}



.firstCard {
    margin-top: 20px;
}

.containerCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
}

.sumary {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    z-index: 100;
}

.imagePrevisto,
.imageGlosado {
    width: 32px;
    height: 25px;
}

.logo {
    width: 106px;
    height: 22px;
}