.tabMenuItem-container {
    background-color: #0D4E33;
    border-radius: 5px;
}



.icon {
    width: 30%;
    height: 30px;
}