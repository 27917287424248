.container_tabelas {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 80px;
    align-items: center;
    width: 400px;
}

.header {
    display: flex;
    align-items: center;
}

.formLabel {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 11px;
    color: #5d5d5d;
}

.inputContainerSmall {
    width: 50%;
    flex-direction: column;
    padding-left: 3px;
    padding-right: 3px;
}

.buttonContainer_tabelas {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 5px;
    width: 100%;
}

.buttonPesquisar_tabelas {
    background-color: #097a5e;
    border-radius: 8px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border: none;
    width: 100%;
}

.buttonCancelar_tabelas {
    background-color: #8b9290;
    border-radius: 8px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border: none;
    margin-left: 10px;
    width: 100%;
}

.buttonText {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.05em;
}

.containerTreatments {
    flex: 1;
    /* background-color: #fff; */
    padding: 20px;
}

.bold {
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: 5px;
}

.normal {
    font-weight: normal;
}

/* .card {
    background-color: #FFFFFF;
  } */

.buttonContainerTreatments {
    flex-direction: row;
    justify-content: space-between;
}

.buttonEnviar {
    background-color: #097a5e;
    border-radius: 8px;
    height: 48px;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.textCenterBold {
    margin-top: 10px;
    font-weight: bold;
    text-align: center;
}

containerScroll {
    padding: 3px 0,
}
