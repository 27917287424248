.container_searchResult {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 80px;
}

.bold {
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: 5px;
}


/* .card {
    background-color: #FFFFFF;
} */

.buttonContainer {
    flex-direction: row;
    justify-content: space-between;
}

.buttonEnviar {
    background-color: #097a5e;
    border-radius: 8px;
    height: 48px;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.buttonText {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.05em;
}

.backgroundImage {
    width: 100%;
    height: 100%;
    flex: 1;
}

.containerFlex {
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-around;
}

.rowDirection {
    flex-direction: row;
    flex-wrap: wrap;
}

.alignCenter {
    align-items: center;
}

.paddingVertical {
    padding-top: 5px;
    padding-bottom: 5px;
}

.justifyBetween {
    justify-content: space-between;
}

.marginVertical {
    margin-top: 5px;
    margin-bottom: 5px;
}

.marginHorizontal {
    margin-left: 5px;
    margin-right: 5px;
}

.containerPadding {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 85px;
}

.containerScroll {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 20%;
}

.containerViewWithoutScroll {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 100%;
}

.contentContainerScrollView {
    min-height: 85%;
    padding-bottom: 5px;
}

.fontBold {
    font-weight: bold;
}

.textButtonPrint {
    font-size: 12px;
}

.backButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.backButtonIcon {
    margin-top: 20px;
}

.headerPage {
    padding: 15px 15px 5px 15px;
}

.backButtonText {
    font-size: 18px;
    color: #0D4E33;
}