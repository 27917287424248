.iconResponsive {
    position: absolute;
    right: 10px;
    top: 10px;
    flex-direction: column;
}



.scrollContainer {
    flex-grow: 1;
}

.lbl_Paymentsmade {
    font-weight: bold;
}

.tit-payment {
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #0D4E33;
}

.table {
    border-width: 1px;
    border-color: #EDEDED;
    margin-bottom: 10px;
    width: 100%;
}

.boldText {
    font-weight: bold;
    display: inline;
}

.filterContainer {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.inputSearch {
    flex-grow: 4;
}

.btnExcel {
    margin-left: 20px;
    margin-right: 20px;
    width: 35px;
    height: 35px;
}

.tableRow {
    display: flex;
    flex-direction: row;
    border-bottom-width: 5px;
    border-bottom-color: #EDEDED;
    width: 100%;
}

.tableHeader {
    background-color: #63B37E;
}

.detailsButton {
    width: 85px;
    height: 40px;
    padding: 8px;
    border-radius: 15px;
    background-color: #0D4E33;
    display: flex;
    align-items: center;
    justify-content: center;
}

.detailsButtonText {
    font-size: 12px;
    color: #FFF;
}

.box {
    display: flex;
    flex-direction: row;
    padding: 8px;
    background-color: #097A5E;
}

.boxLinha {
    width: 33%;
    display: flex;
    flex-direction: column;
}

.textTit {
    font-size: 10px;
    color: #FFF;
    text-align: center;
    padding-bottom: 3px;
}

.textDesc {
    color: #FFF;
    text-align: center;
}

.tableCell {
    flex: 1;
    padding: 10px;
    text-align: center;
    align-items: center;
    display: flex;
}

.headerCell {
    color: #FFFFFF;
    font-weight: bold;
}

.evenRow {
    background-color: #EDEDED;
}

.oddRow {
    background-color: #FFFFFF;
}

.icon {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #FFF;
}

.iconImg {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
}

.columnLayout {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.columnCell {
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    background-color: #63B37E;
    justify-content: space-between;
    align-items: center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 15px;
}

.headerText {
    color: #FFF;
    font-size: 14px;
    font-weight: bold;
}

.body {
    padding: 15px;
    width: 100%;
}

.viewItem {
    display: flex;
    flex-direction: row;
    height: 20px;
    margin: 1px;
}

.viewItemLine {
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin: 1px;
    align-items: center;
}

.tipo {
    font-size: 16px;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
}

.lancamento {
    font-size: 11px;
    padding-left: 5px;
    padding-right: 5px;
    flex: 1;
}

.credito {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 11px;
    color: green;
}

.debito {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    color: #c7323c;
}

.liquido {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    color: orange;
}

.lbl {
    font-size: 14px;
    font-weight: bold;
    flex: 1;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}