.tabMenu-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    z-index: 1000;
    padding: 10px 0;
}

.tabMenu-item {
    margin: 0;
  }