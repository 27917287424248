.sasc-progress-icon {
    z-index: 1;
    margin-top: -30px;
}

.sasc-progress-line {
    border-bottom-color: #C4C4C4;
    border-Bottom-Width: 2px;
    margin: 20px 1px;
    z-index: 0;
    width: 100%;
    height: 5px;
    background-color: #ccca9b;
    border-radius: 2.5px;
}

.sasc-progress-line.active {
    border-bottom-color: #A7A353;
}

.sasc-progress-view-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}