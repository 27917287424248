.button {
    border-radius: 4px;
    padding: 12px 12px;
}

.buttonText {
    font-size: 16px;
    color: #fff;
    text-align: right;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}


.option {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #0D4E33;
    cursor: pointer;
}

.optionText {
    font-size: 16px;
    font-weight: bold;
    color: #0D4E33;
}

.selectedOptionText {
    font-weight: bold;
}

.cardTriangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #FFF transparent transparent transparent;
    transform: rotate(0deg);
    margin-top: -4px;
}