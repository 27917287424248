.formInputErrorCpf {
    border-color: #D32F2F;
}

.formInputCpf {
    height: 47px;
    background-color: #fff;
    border-radius: 8px;
    border-width: 1px;
    border-color: #adadad;
    padding: 10px;
    width: 100%;
}