.viewContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.dataText {
    color: #31444D;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.priceText {
    color: #31444D;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-right: 43px;
}