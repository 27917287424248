.container_AuthenticationFactorsPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

button {
    cursor: pointer;
}

.card_AuthenticationFactorsPage {
    width: 60%;
    height: 100%;
    display: flex;
    /* border-radius: 8px;
    border-width: 0px; */
    /* margin-top: 20px; */
    padding-left: 0;
    padding-right: 0;
    /* overflow: hidden; */
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

}

.logoContainer_AuthenticationFactorsPage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.actionContainer {
    margin-top: 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo_AuthenticationFactorsPage {
    width: 250px;
    height: 52px;
}

.title_AuthenticationFactorsPage {
    font-size: 40px;
    font-weight: 900;
    color: #0d4e33;
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
}

.subtitle_AuthenticationFactorsPage,
.txtObs_AuthenticationFactorsPage {
    font-size: 18px;
    color: .000000;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.authMethodsContainer_AuthenticationFactorsPage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.authMethod_AuthenticationFactorsPage {
    width: 100%;
    height: auto;
    border-radius: 8px;
    border-width: 1px;
    border-color: #0d4e33;
    background-color: #ffffff;
    align-items: center;
    margin-bottom: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
}

.authMethodIcon_AuthenticationFactorsPage,
.authIcon_AuthenticationFactorsPage {
    width: 30px;
    height: 30px;
}

.txt-auth {
    flex: 1;
    margin-left: 10px;
}

.authMethodTitle_AuthenticationFactorsPage {
    border-radius: 25px;
    height: 50px;
    width: 300px;
    text-align: center;
    font-size: 15px;
    background-color: #259d6b;
    font-weight: bold;
    color: #ffffff;
    border: 0;
    flex: 1;
}

/* .authMethodRemoveButton_AuthenticationFactorsPage {
    background-color: #ad0809;
    margin-left: 5px;
} */

/* .authMethodButtonText_AuthenticationFactorsPage, .authMethodRemoveButtonText_AuthenticationFactorsPage {
    font-size: 11px;
    width: 100%;
    height: 100%;
    background-color: #259d6b;
    color: #fff;
    border-radius: 8px;
} */

.authMethodHelp_AuthenticationFactorsPage {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: .759224;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.authMethodRegister_AuthenticationFactorsPage {
    margin-top: 20px;
    display: flex;
    width: 100%;
    text-align: center;
    flex-direction: row;
    align-items: center;
}

.authMethodHelpText_AuthenticationFactorsPage {
    font-size: 13px;
    color: #fff;
}

.finalizeButtonContainer_AuthenticationFactorsPage {
    align-items: center;
}

.finalizeButton_AuthenticationFactorsPage {
    width: 467px;
    height: 48px;
    border-radius: 8px;
    background-color: .097a5e;
    align-items: center;
    justify-content: center;
}

.finalizeButtonText_AuthenticationFactorsPage {
    font-size: 15px;
    font-weight: 700;
    /* letter-spacing: 0.05px; */
    color: #b4e038;
}

.button_validations {
    width: 100px;
    height: 40px;
    border-radius: 10px;
    border: 0;
    background-color: #259d6b;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.button_remove {
    cursor: pointer;
    width: 100px;
    height: 40px;
    border-radius: 10px;
    border: 0;
    background-color: #c7323c;
    color: white;
    font-weight: bold;
}