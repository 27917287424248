.icon {
  width: 22%;
}

.menu label {
  color: black;
  font-style: normal;
  margin-left: 2px;
}

.menu .titulo img {
  height: 28px;
  margin-bottom: 0px;
  filter: brightness(0);
}


.menu hr {
  margin: 0px 0;
}

.menuItem {
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  height: 25px;
  padding-bottom: 25px;
  padding-top: 25px;
  background-color: #FFF;
  border-bottom: 1px solid #0D4E33;
  cursor: pointer;
  transition: all 400ms;
}

.menuItem:hover {
  background-color: #63b37e;
  cursor: pointer;
}


.icon-menu {
  width: 25px;
  height: 22px;
}

.header-menu {
  background-color: rgb(9, 122, 94);
  color: #e8ffe9;
}

.close-menu {
  padding: 10px;
  color: #FFF;
}

.close-menu .btn-close {
  background: url('../../../assets/icons/specific/close.svg') no-repeat center;
  background-size: 10px;
  background-position-x: right;
  background-position-y: center;

  border: none;
  outline: none !important;
}

.button_menu {
  background-color: transparent;
  border: 0px;
  cursor: pointer;
}

.button_menu:hover {
  background-color: transparent;
  border: 0;
}

/* .modal {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 5;
} */

.modalContent {
  background-color: #FFF;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 4px;
  max-height: 100vh;
  width: 20%;
  padding: 8px;
  overflow: hidden;
  color: #FFF;
  overflow: auto;
  color: #0D4E33;
}

.aside_menu {
  position: fixed;
  overflow-y: auto;
  left: 0;
  top: 0;
  width: 320px;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
}

.aside_menu.open {
  transform: translateX(0);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 5;
}

.modalOverlay.open {
  display: block;
}