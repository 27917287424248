titlePage {
    text-transform: uppercase;
    font-size: '18px';
    color: #097A5E;
}

.containerScroll {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 20%;
}

.Perfil-container {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 80px;
}

.header {
    padding: 15px 15px 5px 15px;
}

.backButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.backButtonIcon {
    margin-top: 20px;
}

.headerPage {
    padding: 15px 15px 5px 15px;
}

.backButtonText {
    font-size: 18px;
    color: #0D4E33;
}

.containerScroll {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-bottom: 20%;
}

.formHelperText {
    margin: 0;
    font-weight: 800;
    font-size: 11px;
}

.formErrorText {
    color: #ad0809;
    /* This is the specified theme color for errors */
}

.inputContainer {
    margin: 5px 0;
    width: 100%;
}

.buttonPesquisar,
.buttonCancelar {
    background-color: #097a5e;
    border-radius: 8px;
    height: 48px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.buttonPesquisar {
    margin-right: 8px;
}

.buttonCancelar {
    background-color: #697670;
    margin-left: 8px;
}

.buttonEditPerfil {
    width: 400px;
    height: 40px;
    border-radius: 10px;
    border: 0;
    background-color: #259d6b;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.div-scroll-perfil {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tableCardPerfilPage {
    background-color: #f0f0f0;
    border-radius: 8px;
}

.profile-row-layout {
    height: 100%;
}

.edit-user-button {
    border: 0px;
}