/* .card_registerCodeEmail {
    width: 100%;
    height: 100vh;
    display: flex;
    border-radius: 8px;
    border-width: 0px;
    border-color: #707070;
    background-color: #FFFFFF;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
} */

.logoContainer_registerCodeEmail {
    padding-top: 25px;
    align-items: center;
    flex-grow: 1;
}

.containerTitle_registerCodeEmail {
    flex-grow: 1;
}

.logo_registerCodeEmail {
    width: 250px;
    height: 52px;
}

.title_registerCodeEmail {
    font-size: 40px;
    font-weight: 900;
    color: #0d4e33;
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
}

.subtitle_registerCodeEmail {
    font-size: 12px;
    color: #000000;
    margin-bottom: 20px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
}

.inputContainer_registerCodeEmail {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 24px;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
}

.inputLabel_registerCodeEmail {
    font-size: 12px;
    font-weight: 500;
}

.input_registerCodeEmail {
    height: 48px;
    border-radius: 8px;
    border-width: 1px;
    border-color: #c4c4c4;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
}

.buttonContainer_registerCodeEmail {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    margin-top: 15px;
}

.buttonEnviar_registerCodeEmail,
.buttonCancelar_registerCodeEmail {
    background-color: #259d6b;
    border-radius: 8px;
    border: 0;
    height: 48px;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
}

.buttonEnvia_registerCodeEmailr {
    margin-right: 8px;
}

.buttonCancelar_registerCodeEmail {
    background-color: #697670;
    margin-left: 8px;
}

.buttonText_registerCodeEmail {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.05px;
}

.inputEmail_registerCodeEmail {
    height: 44px;
    background-color: #fff;
    border-radius: 8px;
    border-width: 1px;
    border-color: #adadad;
    width: 294px;
}