.atendimento-container {
  display: flex;
  /* Uncomment if background color is needed */
  /* background-color: #fff; */
  padding: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  overflow: hidden;
}

.containerGroup {
  flex-grow: 1;
  width: 100%;
  height: 80px;
  flex-direction: row;
  flex-wrap: wrap;
}

.iconResponsive {
  position: absolute;
  right: 10px;
  top: 10px;
  flex-direction: column;
}


.scrollContainer {
  flex-grow: 1;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.w100 {
  width: 100%;
}

.formLabel {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 11px;
  color: #5d5d5d;
  width: 100%;
  z-index: 1000000;
}

.formHelperText {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 800;
  font-size: 11px;
}

.formContainer {
  display: flex;
  align-items: center;
}

.formErrorText {
  color: #ad0809;
}

.title {
  font-size: 25px;
  font-weight: bold;
  color: #0d4e33;
  margin-bottom: 30px;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.buttonText {
  font-size: 31px;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: #b4e038;
}

.buttonBackground {
  width: 467px;
  height: 48px;
  border-radius: 8px;
  position: absolute;
  opacity: 0.3;
  background-color: #b4e038;
}

.inputContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
  /* Uncomment if margin is needed */
  /* margin-vertical: 10px; */
}

.hide {
  display: none;
}

.inputContainer2 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
  z-index: 40000;
}

.separator {
  width: 2%;
}

.inputContainerSmall {
  width: 49%;
  display: flex;
  flex-direction: column;
  /* Uncomment if padding is needed */
  /* padding-horizontal: 5px; */
}

.inputContainerFull {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* Uncomment if padding is needed */
  /* padding-horizontal: 5px; */
}