.printGuides-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 80px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.w100 {
  width: 100%;
}

.formLabel {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  color: #5d5d5d;
}

.inputContainer {
  margin: 5px 0;
  width: 100%;
}

.inputContainerSmall {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 3px;
  padding-right: 3px;
}

.buttonContainerTextPrintGuides {
  color: white;
}

.buttonCancelar {
  background-color: #697670;
  margin-left: 8px;
}

.buttonText {
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.05em;
}

.titlePage {
  text-transform: uppercase;
  color: #097A5E;
  margin-top: 40px;
}

.buttonPrintGuides {
  width: 50%;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border: none;
  margin-left: 10px;
}

.buttonContainerTextPrintGuides {
  color: white;
}

.buttonPesquisar {
  margin-right: 8px;
}

.buttonCancelar {
  background-color: #697670;
  margin-left: 8px;
}

.buttonText {
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.05em;
}

.containerScroll {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 20%;
}