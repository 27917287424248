@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli:400,400i,600,600i,700,700i&display=swap');


.App {
  text-align: center;
}

body {

  /* font-family: 'Roboto Slab', serif; */
  font-family: 'Muli', sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.section-form .input-required input {
  border-color: #000;
  border-width: 2px;
  color: #000;
}

.section-form div .form-control {
  min-height: 45px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}




label {
  margin-bottom: 0;
  font-size: 10px;
  color: #000;
  margin-left: 10px;
  font-weight: bold;
  display: inline-block;
}

form label {
  font-weight: 300;
}

input {
  background-color: #fff;
  color: #000000;
  font-size: 14px;
  height: 30px;
  border-radius: 10px;
  padding: 6px 20px;
  border: 1px solid #CCC;

}

input:focus {
  color: #000000 !important;
  box-shadow: none !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.form-row input,
.form-row .Select {
  margin-bottom: 10px;
}

.Select .react-select__control {
  background-color: initial;
  color: #000;
  font-size: 14px;
  height: 45px;
  border-radius: 10px;
  /* padding: 6px 20px; */
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  outline: none;
}



input.is-invalid {
  border: none;
  border: 1px solid #c7323c !important;
}

.form-control::placeholder {
  color: #a9afb5;
  font-weight: 200;
}

.is-invalid .checkbox {
  border-bottom: 2px solid #c7323c;
  padding: 5px;
}

.Select.is-invalid .react-select__control {
  border: 2px solid #c7323c !important;
}

.Selectvalid {
  border: 0px solid #c7323c !important;
  box-shadow: none !important;
  border-radius: 0px;
  background-color: transparent;
}

.form-control.is-invalid,
textarea.form-control.is-invalid {
  border: 2px solid #c7323c !important;
  box-shadow: none !important;
}

.input-required .is-invalid .react-select__control {
  border: 2px solid #c7323c !important;
}

.tooltip.show {
  opacity: 1;
}

.invalid-tip {
  z-index: 9000;
}

.info-tip .tooltip-inner {
  background-color: #2a444eec;
}

.tooltip-inner {
  padding: 10px;
  line-height: 16px;
  color: white;
  font-size: 14px;
  max-width: 230px;
}

.invalid-tip .tooltip-inner {
  background-color: #c7323c;
  box-shadow: 0px 0px 20px #85898e2e;
  border-radius: 50px;
}

.invalid-tip .tooltip-inner {
  background-color: #c7323c;
  box-shadow: 0px 0px 20px #85898e2e;
}


.invalid-tip .arrow.tooltip-arrow::before {
  border-top-color: #c7323c !important;
}



.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #c7323c;
}

.invalid-tip .arrow::before {
  border-top-color: #c7323c;
}


.icon-duvida {
  content: url('./assets/Info_icon.svg');
  width: 18px;
  margin-left: 0px;
  cursor: pointer;
}

.icon-duvida:before {
  content: '\e914';
}

.tool-tip {
  display: inline-block;
}

.tool-tip [disabled] {
  pointer-events: none;
}

.float-button-out {
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 12;
}


.form-group {
  margin-bottom: 0px;
}

p.form-view {
  margin: -3px 0 0 5px;
  line-height: 45px;
}

p.form-view.lines {
  font-size: 16px;
  margin: 0 0 5px 10px;
}

.form-view .tag {
  border: 1px solid gray;
  padding: 0px 5px;
  margin: 0px 2px;
}

.icone-label {
  width: 30px;
}

.icone-label.off {
  filter: brightness(0);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.label-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0;
}

.input-required {
  /* margin-left: 5px;
  margin-right: 5px; */

}

.no-required {
  /* margin-left: 5px;
  margin-right: 5px; */

}

.mb-custom {
  margin-bottom: 300px;
}

.z-negativo {
  z-index: -1;
}

.input-required label {
  font-weight: bold;
}

label {
  color: #000;
  font-size: 12px;
  padding-left: 0px;
  display: inline-block;
  padding: 0px 5px;
}

.form-group.central label {
  padding-left: 0px;
  padding-right: 0px;
}


.form-group>div.form-group .input-group .form-control {
  width: 90%;
}

.form-group>div.form-group .input-group .input-group-text {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.form-group>div.form-group textarea.form-control {
  min-height: 54px;
}

.form-group>div.form-group.central .form-control {
  text-align: center;
}

div.form-group .input-required input {
  border-color: #000000;
  border-width: 2px;
  color: #000000;
}

div.form-group .form-control:disabled,
div.form-group .form-control[readonly] {
  color: #85898e;
  font-weight: bold;
  background-color: #FFF;
  border-bottom: 2px solid #85898e;
}





.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control {
  background-color: initial;
  color: #000;
  font-size: 14px;
  height: 45px;
  border-radius: 10px;
  padding: 6px 20px;
}



.form-inside .section-form>div.form-group textarea.textarea-modelo {
  background-color: #f2f5f6;
  padding: 10px;
  min-height: 150px;
  border-radius: 5px;
}

.receituario-content .form-control {
  padding: 8px 5px;
  line-height: 30px;
}

.form-control:focus {
  box-shadow: none;
  background-color: transparent;
}

textarea.form-control {
  background-color: white;
  border-radius: 10px;
  min-height: 50px;
  border: 1px solid #f0f5f9;
  resize: none;
}

textarea.form-control:focus {
  color: black;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

span.icon-ver {
  content: url('./assets/icon_visualizar.svg');
  width: 20px;
  float: right;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  cursor: pointer;
}

span.icon-ocultar {
  content: url('./assets/icon_ocultar.svg');
  width: 20px;
  float: right;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  cursor: pointer;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child),
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px
}

.input-group-append {
  position: absolute;
  right: 2px;
  top: 12px;
  background-color: white;
  border-radius: 10px;
  z-index: 4;
}

.is-invalid .input-group-append {
  display: none;
}

.input-group-text {
  background-color: transparent;
  border: none;
}

.is-invalid .input-group-text {
  border: 2px solid #c7323c;
}


input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}