/* .codeSmsPage_card {
    width: 100%;
    height: 100vh;
    border-radius: 8px;
    border-width: 0px;
    border-color: #707070;
    background-color: #FFFFFF;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */

.codeSmsPage_logoContainer {
    padding-top: 25px;
    align-items: center;
    flex-grow: 1;
}

.codeSmsPage_containerTitle {
    flex-grow: 1;
    height: 100px;
}

.codeSmsPage_logo {
    width: 250px;
    height: 52px;
}

.codeSmsPage_title {
    font-size: 40px;
    font-weight: 900; /* Replace with the closest font-weight available or custom font face */
    color: #0d4e33;
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
}

.codeSmsPage_subtitle {
    font-size: 14px;
    color: #000000;
    margin-bottom: 20px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
}

.codeSmsPage_inputContainer {
    margin-bottom: 24px;
    flex-grow: 2;
}

.codeSmsPage_inputLabel {
    font-size: 12px;
    font-weight: 500;
}

.codeSmsPage_input {
    height: 48px;
    border-radius: 8px;
    border-width: 1px;
    border-color: #c4c4c4;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
}

.codeSmsPage_buttonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
}

.codeSmsPage_buttonEnviar {
    background-color: #259d6b;
    color: #fff;
    border-radius: 8px;
    font-weight: bold;
    height: 48px;
    flex: 1;
    border: 0;
    justify-content: center;
    align-items: center;
}

.codeSmsPage_buttonCancelar {
    background-color: #697670;
    border-radius: 8px;
    height: 48px;
    flex: 1;
    margin-left: 8px;
    justify-content: center;
    align-items: center;
}

.codeSmsPage_buttonText {
    font-size: 15px;
    font-weight: 700; /* Replace with the closest font-weight available or custom font face */
    color: #fff;
    letter-spacing: 0.05px;
}
