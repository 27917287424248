.icon {
    width: 22%;
    height: 22px;
}



.container_menuPerfil .boas-vindas {
    font-size: 14px;
    width: 320px;

}

.buttonMenuPerfil {
    margin-right: 0px;
    border: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 80px;
    cursor: pointer;
}

.buttonMenuPerfil:hover {
    background-color: transparent;
    border: 0px solid black;
    border-radius: 0px;

}

.modal_perfil {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.modalContentPerfil {
    background-color: #FFF;
    border-width: 1px;
    border-color: #ccc;
    border-radius: 4px;
    max-height: 100vh;
    width: 20%;
    padding: 8px;
    overflow: hidden;
    color: #FFF;
    overflow: auto;
    color: #0D4E33;
}



/* 
.modalContent {
    background-color: #FFF;
    border-width: 1px;
    border-color: #ccc;
    border-radius: 4px;
    max-height: 100vh;
    width: 20%;
    padding: 8px;
    overflow: hidden;
    color: #FFF;
    overflow: auto;
    color: #0D4E33;
} */

.aside_menu_perfil {
    position: fixed;
    overflow-y: auto;
    right: 0;
    /* Changed from left to right */
    top: 0;
    width: 320px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    /* Changed the direction of the shadow */
    transform: translateX(100%);
    /* Changed the initial transform */
    transition: transform 0.3s ease-in-out;
    /* Added for smooth transitioning */
}

.aside_menu_perfil.open-perfil {
    transform: translateX(0);
    /* No change needed here */
}

.modalOverlayPerfil {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 5;
}

.modalOverlayPerfil.open-perfil {
    display: block;
}