.buttonPrimary {
    border: none;
    border-radius: 5px;
    width: 300px;
    height: 50px;
    background-color: var(--primary-color);
}

.textPrimary {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}