.buttonSecondary {
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4D90FE;
    border: none;
    cursor: pointer;
    padding: 10px;
    width: 300px;
    height: 50px;
}

.textSecondary {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}