.descriptionContainer_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.logo_card {
    width: 30px;
    height: 30px;
}

.card-button {
    cursor: pointer;
}

.label_card {
    font-size: 16px;
    font-weight: normal;
    margin-left: 10px;
    margin-right: 10px;
    color: #097A5E;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 0;
}



/* .iconContainer { */
/* Additional styles for absolute positioning if needed */
/* } */

/* .iconBackground_card {
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    display: flex;
} */

.icon {
    width: 21px;
    height: 21px;
    opacity: 0.6;
}