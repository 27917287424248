.defaultStyles-mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.container-fluid>.container:first-of-type {
    padding-top: 0;
}

.container-fluid> :not(.container)+.container {

    padding-top: 120px;
    /* ou qualquer outro estilo desejado */
}

.defaultStyles-containernoPadding {
    height: screenHeight * 0.75;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.btn {
    border-radius: 10px;
    border: 0;
    font-weight: bold;
}

.btn-secondary {
    border: 1px solid #e3e7e8;
    color: white;
    background: #097A5E;
    background-color: #097A5E;
    color: white;
    min-width: 150px;
}


.btn-secondary:focus {
    border: 1px solid #d8dde3 !important;
    box-shadow: -5px -5px 10px #ffffff88, 5px 5px 10px #85898e21;
    /* color: black; */
}

.btn-secondary:active {
    border: 1px solid transparent !important;
    color: white !important;
}

.btn-secondary:hover {
    color: white;
    background: #0D4E33
}


.defaultStyles-row {
    display: flex;
    flex-direction: row;
}

.defaultStyles-textCenterBold {
    margin-top: 10px;
    font-weight: bold;
    text-align: center;
}

.defaultStyles-zIndex {
    z-index: 2;
}

.defaultStyles-titlePage {
    text-transform: uppercase;
    font-size: 24px;
    color: #097A5E;
    margin-top: 50px;
}

.defaultStyles-titlePageCenter {
    text-align: center;
    text-transform: uppercase;
    font-size: 26px;
    color: #097A5E;
    margin-bottom: 20px;
    margin-top: 0px;
}

.defaultStyles-formLabel {
    margin: 0;
    font-weight: 600;
    font-size: 11px;
    color: #5d5d5d;
}

/* .defaultStyles_btnExcelCard {
    width: 25px;
    height: 25px;
    margin-left: 5px;
} */

.defaultStyles-headerInput {
    padding-bottom: 0;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.defaultStyles-headerInput+.defaultStyles-titlePageCenter {
    margin-top: 0px;
    margin-bottom: 5px;
}

.defaultStyles-halfBack {
    width: 50%;
    margin-bottom: 10px;
}

.defaultStyles-inputContainerYear {
    flex: 1;
    width: 50%;
    margin-top: 10px;
    margin-left: auto;
}

.defaultStyles-tableCellCard {
    flex: 1;
    padding: 10px;
    align-items: center;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
}

.defaultStyles-fullWidth {
    width: 92%;
}

.defaultStyles-formHelperText {
    margin: 0;
    font-weight: 800;
    font-size: 11px;
}

.defaultStyles-formErrorText {
    color: #ad0809;
}

.defaultStyles-tableColumnCard {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.defaultStyles-headerCellCard {
    color: #FFFFFF;
    font-weight: bold;
}

.defaultStyles-evenRowCard {
    background-color: #EDEDED;
}

.defaultStyles-oddRowCard {
    background-color: #FFFFFF;
}

.defaultStyles-evenRowCardGreen {
    background-color: #dee6e3;
}

.textAreaCustom {
    min-height: 50px;
}

.titlePage {
    text-transform: uppercase;
    font-size: '18px';
    color: #097A5E;
}


.defaultStyles-iconImgCard {
    width: 35px;
    height: 35px;
    margin: 10px 0;
    margin-left: 10px;
}

.sinal {
    width: 25px;
    height: 25px;
    border-radius: 50px;
}

.defaultStyles-iconImgCard.defaultStyles-btnExcelCard {
    margin-top: 25px;
}

.defaultStyles-columnLayoutCard {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
}

.defaultStyles-tableRowCard {

    border-color: #d2d2d2;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 10px;
}

.defaultStyles-rowLayoutCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.defaultStyles-inputContainer {
    margin: 5px 0;
    width: 100%;
}

.defaultStyles-backButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.defaultStyles-NewBackButton {
    /* position: absolute; */
    left: 10px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-color: transparent;
}

.defaultStyles-NewBackButtonToLeft {
    position: absolute;
    left: 10px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-color: transparent;
}

.defaultStyles-newBackButtonText {
    font-size: 25px;
    color: #0D4E33;
    display: flex;
    line-height: 20px;
}

.defaultStyles_backButtonIcon {
    margin-top: 20px;
}

.defaultStyles-header {
    padding: 15px 15px 5px 15px;
}

.defaultStyles_backButtonText {
    font-size: 18px;
    color: #0D4E33;
}



.defaultStyles-newFilterContainerCard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

/* .defaultStyles_inputSearchCard {
    flex: 3px;
    max-height: 40px;
} */

.defaultStyles-tableCard {
    border-color: #EDEDED;
    text-align: center;

}

.defaultStyles- {
    border: 1px solid black;
}

.defaultStyles-rowCard {
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.defaultStyles-boldText {
    font-weight: bold;
}

.defaultStyles-wrap {
    flex-wrap: wrap;
}

.defaultStyles-iconsCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: none;
    background-color: transparent;
    margin: 5px 0;
}

.defaultStyles-iconCard {
    align-items: stretch;

}

.defaultStyles-iconsCard button {
    color: #707070;
    font-size: 14px;
}

.defaultStyles-firstCard {
    margin-top: 10px;
}

.defaultStyles-imagePrevisto {
    width: 32px;
    height: 25px;
}

.defaultStyles-imageGlosado {
    width: 32px;
    height: 25px;
}

.defaultStyles-logo {
    /* width: 250px; */
    height: 47px;
}

.defaultStyles-noMarginNoPadding {
    padding: 0;
    margin: 0;
}

.defaultStyles-subtitle {
    font-size: 14px;
    color: #000000;
    margin-bottom: 20px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
}

.defaultStyles-titleLoginPages {
    font-size: 40px;
    font-weight: 900;
    color: #0d4e33;
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
}

.defaultStyles-container {
    width: 100%;
    height: 50px;
    padding: 0;
    margin: 0;
    overflow: visible;
    justify-content: center;
    align-items: center;
}

.defaultStyles-buttonPesquisar {
    width: 100%;
    border-radius: 8px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border: none;
    margin-left: 10px;
}

.defaultStyles-buttonText {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.05em;
}

.defaultStyles-buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
}

.defaultStyles-smallButton {
    width: 120px;
    height: 48px;
    border-radius: 10px;
    border: 0;
    background-color: #097A5E;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.defaultStyles-avgButton {
    width: 300px;
    height: 48px;
    border-radius: 10px;
    border: 0;
    background-color: #097A5E;
    color: white;
    font-weight: bold;
    cursor: pointer;
}