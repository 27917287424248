.sascCard-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sasccard-containerCard {
    margin: 1px 0;
}

.card-custom {
    margin-left: 0px;
    margin-right: 0px;
    background-color: #FFFFFF;
    border-radius: 10px;
    border-width: 0;
    padding: 20px;
}