/* .container {
    display: flex;
    background-color: #fff;
    padding: 20px;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 100px;
    overflow: hidden;
} */

.iconResponsive {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    flex-direction: column;
}



.scrollContainer {
    flex-grow: 1;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.w100 {
    width: 100%;
}

.saveButton_medicalCare {
    width: 85px;
    height: 40px;
    padding: 8px;
    border-radius: 15px;
    background-color: #0D4E33;
    display: flex;
    align-items: center;
    justify-content: center;
}

.saveButtonText_medicalCare {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.headerText {
    font-size: 20px;
    color: #097A5E;
    margin-left: 10px;
    margin-right: 10px;
}

.formLabel {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 11px;
    color: #5d5d5d;
}

.formHelperText {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 11px;
}

.formContainer {
    display: flex;
    align-items: center;
}

.formErrorText {
    color: #ad0809;
}

.title {
    font-size: 25px;
    font-weight: bold;
    color: #0d4e33;
    margin-bottom: 30px;
}

.buttonsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.buttonText {
    font-size: 31px;
    font-weight: bold;
    letter-spacing: 0.05em;
    /* Uncomment if font-family is needed */
    /* font-family: 'Montserrat-Bold'; */
    color: #b4e038;
}

.buttonBackground {
    width: 467px;
    height: 48px;
    border-radius: 8px;
    position: absolute;
    opacity: 0.3;
    background-color: #b4e038;
}

.inputContainer {
    margin-top: 0;
    width: 100%;
}

.inputContainerFirstStep {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.formLabelFirstStep {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 11px;
    color: #5d5d5d;
}

.viewContainerFull {
    width: 100%;
}

.viewContainerAfterFull {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.viewContainerBetween {
    width: 2%;
}

.textInsertDisabledThirdStep {
    text-decoration-line: underline;
    color: #999999;
}

.textInsertThirdStep {
    text-decoration-line: underline;
    color: #000000;
}

.viewPressableThirdStep {
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.iconPlusThirdStep {
    padding-left: 5px;
    padding-right: 5px;
}