.inputContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80px;
}
  


.containerView {
    display: flex;
    align-items: center;
}
  
.text500ColorGray {
    color: #31444D;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}
  
.text400ColorBlack {
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 8px;
}
  
.containerFlex {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 200px;
    justify-content: space-around;
}
  
.text400ColorBlackUnderline {
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 20px;
    text-decoration: underline;
}
  
.text500ColorBlackBold {
    color: #000000;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 16px;
    align-self: center;
    margin-bottom: 10px;
}
  
.viewRowSpaceBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
  
.containerViewDashed {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20px;
}
  
.viewDashed {
    border-width: 1px;
    border-style: dashed;
    border-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    border-radius: 1px;
}
  
.containerViewProcedureNumber {
    width: 6%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
  
.procedureNumber {
    font-size: 16px;
    line-height: 19px;
    color: #31444D;
    font-weight: 500;
}
  
.containerRootViewDashed {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10px;
}
  
.textButtonPrint {
    font-size: 12px;
}
  