/* .carousel-item-button {

} */



.activeSlide {
    background-color: #fff;
    border-radius: 10px;
}

.cardTitle {
    color: #000;
    font-size: 12px;
    letter-spacing: 0.008px;
    text-align: center;
    margin-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
}

.cardTitleActive {
    text-transform: uppercase;
    color: #097A5E;
    font-weight: bold;
}

.cardTriangle {
    position: absolute;
    bottom: -10px;
    width: 0%;
    height: 0px;
    border-top-width: 10px;
    border-right-width: 10px;
    border-bottom-width: 0px;
    border-left-width: 10px;
    border-top-color: #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    margin-Top: -4px;
    transform: rotate(0deg);
}

.containerSummaryMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    background-color: #ededed;
    border-bottom: 1px solid #ccc;
    border-top: 1% solid #ccc;
    margin-bottom: 20px;
}

/* .icon-button {
    color: #097A5E;
    size: 30px;
} */