.custom-file-upload {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    text-align: center;
}

.custom-file-upload input[type="file"] {
    display: none;
}

.fd-imagem {
    background-color: #e9ecef;
    border-radius: 5px;
    border: 1px solid #ced4da;
    height: 170px;
}