.cheeseburger-menu {
  z-index: 1050;
}

.cheeseburger-menu-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
}

.cheeseburger-menu-outer {
  background: #f8f8f8 !important;
}


.cheeseburger-menu-inner {
  padding: 0px;
}

.cheeseburger-menu-inner .titulo {
  font-weight: bold;
  margin-left: 0px;
}

.cheeseburger-menu-inner .menu ul {
  padding: 0;
}

.cheeseburger-menu-inner .menu hr {
  margin: 0 auto;
  width: 80%;
}

.cheeseburger-menu-inner .menu ul li {
  display: flex;
  align-items: center;
  list-style: none;
  height: 65px;
  padding: 0 20px;
  border-radius: 20px;
  transition: all 250ms;
  cursor: pointer;
}

.cheeseburger-menu-inner .menu ul li a {
  display: inline-block;
  width: 100%;
}

.cheeseburger-menu-inner .menu ul li img {
  width: 30px;
  margin-right: 10px;
  filter: brightness(0);
  transition: all 250ms;
}

.cheeseburger-menu-inner .menu ul li span {
  /* font-size: $font-default; */
}

.cheeseburger-menu-inner .menu ul li:hover {
  background: white;
}

.cheeseburger-menu-inner .menu ul li:hover img {
  filter: brightness(1);
}

.cheeseburger-menu-inner .nome-user {
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
}

.cheeseburger-menu-inner .tipo-user {
  font-size: 14px;
  line-height: 14px;
  color: #85898e;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cheeseburger-menu-inner .meu-perfil {
  cursor: pointer;
  padding: 20px;
  border-radius: 20px;
  transition: all 250ms;
}

.cheeseburger-menu-inner .meu-perfil:hover {
  background: white;
}

.cheeseburger-menu-inner .meus-dados img {
  width: 26px;
  filter: brightness(0);
  transition: all 250ms;
}

.cheeseburger-menu-inner .meus-dados p {
  margin: 0 14px 3px 0;
}



.cheeseburger-menu-inner .meu-perfil:hover .meus-dados img {
  filter: brightness(1);
}

.cheeseburger-menu-inner .menu ul li:last-child,
.cheeseburger-menu-inner .menu ul li:last-child a,
.cheeseburger-menu-inner .menu ul li:last-child img {}

.close-menu>div {
  font-size: 12px;
  color: black;
}

.close-menu .btn-close {
  /* background: url('./images/icones/close.svg') no-repeat center; */
  background-size: 10px;
  background-position-x: right;
  background-position-y: center;
  opacity: 0.3;
  border: none;
  outline: none !important;
}

@media (max-width: 1023px) {
  .cheeseburger-menu-outer {
    width: 100vw !important;
    max-width: 100% !important;
  }
}