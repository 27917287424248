.title {
    color: #000000;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 16px;
    align-self: center;
    margin-bottom: 10px;
}

.text {
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 8px;
    margin-right: 10px;
}

.rowContainer {
    flex-direction: row;
    justify-content: space-between;
}