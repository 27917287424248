.Usuariocontainer {
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    height: 0.75vh;
}


.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.w100 {
    width: 100%;
}

.formLabel {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 11px;
    color: #5d5d5d;
}

.inputContainerSmall {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 3px;
    padding-right: 3px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
}

.buttonPesquisar,
.buttonCancelar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 48px;
    flex-grow: 1;
}

.buttonPesquisar {
    background-color: #097a5e;
}

.buttonCancelar {
    width: 50%;
    background-color: #697670;
    margin-left: 8px;
}

.buttonText {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.05px;
}

.formHelperText {
    margin: 0;
    font-weight: 800;
    font-size: 11px;
}

.formErrorText {
    color: #ad0809, ;
}



.buttonAddList {
    background-color: #097a5e;
    border-radius: 8px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.buttonTextList {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.05px;
}

.iconCard {
    align-items: stretch;
    padding: 10px 0;
    border: none;
}

/* .buttonContainer {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
} */

/* .buttonAdd {
    background-color: #097a5e;
    border-radius: 8px;
    height: 48px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.05px;
    cursor: pointer;
  } */

.scrollContainerMarginBottom {
    margin-bottom: 200px;
}

.boldText {
    font-weight: bold;
    margin-right: 5px;
}

.rowCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.tableCellCard {
    flex: 1;
    padding: 10px;
    align-items: center;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
}

.fullWidth {
    width: 92%;
}

.tableCard {
    border-color: #EDEDED;
    margin: 10px 0;
    width: 100%;
    /* border-style: solid; */
    border-width: 1px;
}

.rowLayoutCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    width: 100%;
}

.columnLayoutCard {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
}

.oddRowCard {
    background-color: #FFFFFF;
}

.evenRowCard {
    background-color: #EDEDED;
}

.userRow {
    margin-bottom: 10px;
    border: 1px solid #ccc;
}

.buttonSendUsuario {
    width: 300px;
    height: 50px;
    border-radius: 10px;
    border: 0;
    background-color: #259d6b;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.container-input-usuario {
    padding-top: 10px;
    padding-bottom: 10px;
}