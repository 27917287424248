.inputContainerPassword {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    /* padding-right: 30px; */
    position: relative;
}

.formInputPassword {
    height: 27px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #adadad;
    padding: 10px;
    width: 100%;
}

.formInputErrorPassword {
    border-color: #D32F2F;
}

.eyePassword {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}