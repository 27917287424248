.container-login {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    margin-top: 100px;
}

.inputsAndButtons {
    align-items: center;
}

.formLabel_CPF_CNPJ {
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 13px;
    color: #5d5d5d;
    margin-right: 10px;
}

.formLabel_password {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 13px;
    color: #5d5d5d;
    margin-right: 10px;
}

.w100 {
    width: 100%;
}

.backButtonLoginDoctor {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-color: transparent;
}

.backButtonText {
    font-size: 18px;
    color: #0D4E33;
    display: flex;
    align-items: center;
}

.backButtonText svg {
    margin-right: 5px;
    /* Adiciona um espaço entre o ícone e o texto */
}

.headerLoginDoctor {
    padding-top: 35px;
}

.welcomeView {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-bottom: 50px;
}

.welcomeTextLoginDoctor {
    font-weight: 900;
    font-size: 17px;
}

.formHelperText {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 11px;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.logoLoginDoctor {
    width: 250px;
    height: 52px;
    margin-bottom: 30px;
}

.formContainerLoginDoctor {
    display: flex;
    justify-content: center;
    flex: 5;
    padding-left: 5px;
    flex-direction: column;
    width: 350px;
}

.formErrorText {
    color: #ad0809;
}

.title {
    font-size: 25px;
    font-weight: bold;
    color: #0d4e33;
    margin-bottom: 30px;
}

.buttonsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.esqueciSenhaView {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    text-decoration: underline;
}

.esqueciSenhaTextLoginDoctor {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

/* .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
} */

.buttonText {
    font-size: 31px;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: #b4e038;
}

.buttonBackground {
    width: 467px;
    height: 48px;
    border-radius: 8px;
    position: absolute;
    opacity: 0.3;
    background-color: #b4e038;
}

/* .checkboxContainer {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #080808;
} */

.checkboxBox {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    border-width: 1px;
    border-color: #C4C4C4;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxCheck {
    width: 12px;
    height: 12px;
    background-color: #B4E038;
    border-radius: 2px;
}

.checkboxText {
    font-size: 12px;
    color: #080808;
}

.inputsContainers {
    align-items: flex-start;
    align-items: center;
    width: 100%;
}

.inputContainer_loginDoctor {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.inputContainer_password {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.inputBox {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border-width: 1px;
    border-color: #ADADAD;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    color: #080808;
    margin-bottom: 10px;
}

.submitButtonContainer {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
}

.submitButton {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background-color: #B4E038;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerText {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 10px;
    font-weight: 900;
    text-align: center;
    background-color: #fff;
    padding: 10px 0;
}