.viewContainer {
    flex-direction: row;
    justify-content: flex-start;
}

.text {
    color: #31444D;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}