/* Verificar se está correto o uso do 'vh' em vez de usar a constante screenHeight */
.containerCard {
    height:  0.75vh;
    padding: 20px;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}

/* Verificar se imageStyle é também aplicado no .css */
.imagePrevisto {
    width: 32px;
    height: 25px;
}

.imageGlosado {
    width: 32px;
    height: 25px;
}

.sumary {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.entidadePage_sumary {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.CardContainer-Sasc {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.entidadePage_containerCard {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.entidadePage_containerSumary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.entidadePage_FormGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
