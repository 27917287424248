#container_SelectRoleLoginPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.card_SelectRoleLoginPage {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 650px;
    width: 550px;
    height: 600px;
    flex-direction: column;
    border-radius: 8px;
    padding: 10px;
    overflow: hidden;
}

#logoContainer_SelectRoleLoginPage {
    align-items: center;
    margin-bottom: 100px;
}

#logo_SelectRoleLoginPage {
    width: 312px;
    height: 65px;
}

#formContainer_SelectRoleLoginPage {
    align-items: center;
}

#title_SelectRoleLoginPage {
    font-size: 25px;
    font-weight: bold;
    color: #0d4e33;
    margin-bottom: 30px;
}

#buttonsContainer_SelectRoleLoginPage {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}

#buttonText_SelectRoleLoginPage {
    font-size: 31px;
    font-weight: bold;
    /* letter-spacing: 0#05px; */
    color: #b4e038;
}

#buttonBackground_SelectRoleLoginPage {
    width: 467px;
    height: 48px;
    border-radius: 8px;
    position: absolute;
    /* opacity: 0#3; */
    background-color: #b4e038;
}

.shadowBorder_SelectRoleLoginPage {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

#buttons_SelectRoleLoginPage {
    color: white;
    font-size: 15px;
    font-weight: bold;
}
