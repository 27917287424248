@import '../../../themes/theme.css';

.formInputError {
    border-color: var(--error-color);
}

/* .formInputError {
    border-color: #D32F2F;
} */

.formInput {
    height: 47px;
    background-color: #fff;
    border-radius: 8px;
    border-width: 1px;
    border-color: #adadad;
    width: 100%;
}