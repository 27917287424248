.stylesDetail-procedureText {
    font-size: 14px;
    line-height: 16px;
    color: #31444D;
    font-weight: 500;
    margin-bottom: 15px;
}

.stylesDetail-containerView {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 30px;
}

.stylesDetail-containerViewDashed {
    width: 44%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20px;
}

.stylesDetail-viewDashed {
    border-width: 1px;
    border-style: dashed;
    border-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    border-radius: 1px;
}

.stylesDetail-containerViewProcedureNumber {
    width: 6%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.stylesDetail-procedureNumber {
    font-size: 16px;
    line-height: 19px;
    color: #31444D;
    font-weight: 500;
}

.stylesDetail-containerViewIcon {
    width: 6%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}