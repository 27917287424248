/* .container {
    display: flex;
    flex: 1;
    padding: 20px;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 80px;
} */

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.formLabel {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 11px;
    color: #5d5d5d;
}

.containerGroup {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 80px;
    flex-direction: row;
    flex-wrap: wrap;
}

.inputContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    height: 80px;
}

.separator {
    width: 2%;
}

.inputContainerSmall {
    width: 49%;
    display: flex;
    flex-direction: column;
}

.buttonContainer_search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
    width: 100%;
}

.buttonPesquisar {
    background-color: #097a5e;
    border-radius: 8px;
    height: 48px;
    flex: 1;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.buttonCancelar {
    width: 50%;
    background-color: #697670;
    border-radius: 8px;
    height: 48px;
    flex: 1;
    margin-left: 8px;
    justify-content: center;
    align-items: center;
}

.buttonText {
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.05px;
}

.searchTitlePage {
    text-transform: uppercase;
    color: #097A5E;
    margin-top: 50px;
}

.search-container {
    display: flex;
    width: 400px;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
}

.buttonPesquisarSearch {
    width: 100%;
    background-color: #097a5e;
    border-radius: 8px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border: none;
}

.buttonLimparSearch {
    width: 100%;
    background-color: #8b9290;
    border-radius: 8px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border: none;
    margin-left: 10px;
}
