.codeLoginPage_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
}

.codeLoginPage_card {
    display: flex;
    width: 100%;
    height: 90%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.codeLoginPage_logoContainer {
    align-items: center;
}

.codeLoginPage_formLabel {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 11px;
    color: #5d5d5d;
}

.codeLoginPage_formHelperText {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 800;
    font-size: 11px;
}

.codeLoginPage_logo {
    width: 250px;
    height: 52px;
}

.codeLoginPage_formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 100px;
    padding: 0;
    height: 300px;
    width: 400px;
}

.codeLoginPage_title {
    font-size: 25px;
    font-weight: bold;
    color: #0d4e33;
}

.codeLoginPage_subtitle {
    font-size: 13px;
    font-weight: 500;
    color: #0d4e33;
    margin-bottom: 5px;
}

.codeLoginPage_inputContainer {
    width: 90%;
}

.codeLoginPage_inputLabel {
    font-size: 12px;
    font-weight: 600;
    color: #080808;
    margin-bottom: 10px;
}

.codeLoginPage_inputBox {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border-width: 1px;
    border-color: #ADADAD;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    color: #080808;
    margin-bottom: 10px;
}

.codeLoginPage_button {
    background-color: #097a5e;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.05px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.codeLoginPage_resendContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 38px;
}

.codeLoginPage_resend {
    font-size: 14px;
    font-weight: 500;
    color: #7c7c7c;
}

.codeLoginPage_buttonContainer {
    background-color: #259d6b;
    color: #fff;
    border-radius: 8px;
    padding: 15px;
    justify-content: center;
    align-items: center;
    border: 0;
    font-weight: bold;
    margin-top: 15px;
}

.code_inputForm {
    width: 300px;
}

.codeLoginPage_resendButton {
    margin-top: 5px;
    background-color: #259d6b;
    color: #fff;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 0;
    padding: 15px;
    font-size: 12px;
    font-weight: bold;
}

.codeLoginPage_resendTextButton {}