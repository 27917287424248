.modal {
    display: block;
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.hidden {
    display: none;
}

.centeredView {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 22px;
}

.modalView {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.title {
    font-size: 16px;
    color: #097A5E;
    font-weight: bold;
    margin-bottom: 10px;
}

.containerScroll {
    padding: 3px 0;
}

.evenRowCardGreen {
    background-color: #dee6e3;
}

.oddRowCard {
    background-color: #FFFFFF;
}

.ph10 {
    padding: 5px 10px;
}

.modalText {
    margin-bottom: 15px;
    width: 100%;
}

.itemContainer {
    border-bottom-color: #0D4E33;
    border-bottom-width: 1px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.header-payment {
    flex-direction: column;
    background-color: #63b37e;
    justify-content: space-between;
    align-items: flex-start;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px;
}

.bold {
    font-weight: bold;
}

.headerText {
    color: #FFF;
    font-size: 14px;
}



.button {
    border-radius: 20px;
    padding: 10px;
    width: 150px;
    height: 40px;
    margin-top: 10px;
    background-color: #097A5E;
    color: white;
    text-align: center;
    border: none;
    cursor: pointer;
}

.buttonClose {
    background-color: #097A5E;
}