.viewContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.viewImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.image {
    width: 71px;
    height: 43px;
}

.viewText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
}

.textFileName {
    padding-bottom: 3px;
    color: #31444D;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
}

.textDate {
    color: #31444D;
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 14px;
}