.viewDSP {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logoContainerDSP {
    padding: 50px;
}

.logoDSP {
    width: 312px;
    height: 65px;
    object-fit: contain;
}

.titleDSP {
    font-size: 22px;
    color: #0d4e33;
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
}

.subtitleDSP {
    font-size: 14px;
    color: #000;
    margin-bottom: 24px;
}

.buttonContainerDSP {
    width: 100%;
    flex: 1;
    justify-content: center;
    align-items: flex-end;
    margin-top: auto;
}

.buttonDSP {
    background-color: #097a5e;
    width: 200px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
}

.viewContainerDSP {
    justify-content: flex-start;
    text-align: flex-start;
}

.viewContainerInlineDSP {
    width: 100%;
    margin-bottom: 15px;
}

.viewContainerInlineBlocoDSP {
    display: flex; /* Ativar flexbox */
    align-items: center; /* Centralizar verticalmente */
    margin-bottom: 15px;
}

.SistemaText {
    margin-left: 10px; /* Mantém a margem à esquerda */
    display: inline-block; /* Permite centralizar o texto */
    vertical-align: middle; /* Centraliza verticalmente em relação ao Switch */
}

.buttonDSP-defaultSystem {
    margin-top: 30px;
    background-color: #259d6b;
    font-weight: bold;
    width: 200px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
}