/* .logoContainer {
    display: flex;
    align-items: center;
}

.top {
    background-color: #fff;
    display: flex;
    width: 100%;
    margin-top: 25px;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.firstCard {
    margin-top: 20px;
}

.containerCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.sumary {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 100;
}

.imagePrevisto,
.imageGlosado {
    width: 32px;
    height: 25px;
}

.logo {
    width: 106px;
    height: 22px;
} */

.homePage_sumary {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.homePage_containerSumary {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.homePage_containerCard {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.homePage_Atendimento {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;

}

.homePage_Atendimento>.atalho_atendimento {
    border-radius: 5px;
    height: 50px;
    margin-top: 30px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    width: calc(33.33%);
    box-sizing: border-box;
    background-color: #0a7a5e;
    color: white;
    align-items: center;
    cursor: pointer;

}

.homePage_Atendimento {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;

}

.homePage_Atendimento_btn {
    background-color: #4CAF50;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    height: 60px;
    margin-top: 30px;
    justify-content: center;
    display: flex;
    /* width: calc(33.33%); */
    box-sizing: border-box;
    background-color: #0a7a5e;
    color: white;
    align-items: center;
    cursor: pointer;
}

.homePage_Atendimento img {
    vertical-align: middle;
    margin-right: 10px;
}

.cardContainer_home {
    border-radius: 0;
    height: 100px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    background-color: lightgray;
    align-items: center;
    cursor: pointer;
}

.homePage_containerCard .cardContainer_home {
    margin: 0.5rem
}