/* .container {
    display: flex;
    flex: 1;
    padding: 20px;
} */

.bold {
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: 5px;
}



.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.buttonEnviar {
    background-color: #097a5e;
    border-radius: 8px;
    height: 48px;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.buttonText {
    font-size: 15px;
    font-weight: 700;
    /* Replace with the closest font-weight available or custom font face */
    color: #fff;
    letter-spacing: 0.05px;
}



.itemDetails {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
}

.headerContainer {
    background-color: #259d6b;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    padding: 10px;
    color: #fff;
}

.bordered-cell {
    border: 1px solid #ccc;
    padding: 5px;
    margin-bottom: 5px;
}

.acc {
    margin-top: 20px;
}

.acc-header,
.accordion-button,
.accordion-item:first-of-type>.accordion-header .accordion-button {
    background-color: #097a5e;
    color: #fff;
}

.acc-body {
    background-color: #f1f1f1;
}

.acc-item {
    color: #fff;
}

.itemDetails {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-bottom: -5px;
    margin-top: 5px;
}

.itemDetailDado {
    flex: 1 0 calc(33% - 10px);
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    padding: 5px 10px;
    text-align: left;
}

.text-item-detail {
    font-weight: normal;
    text-align: left;

}

.paciente {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.dataItem {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
}

.numeroItem {
    font-size: 14px;
    margin-bottom: 5px;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
}

.buttonSendJustification {
    width: 100px;
    height: 40px;
    border-radius: 10px;
    border: 0;
    background-color: #259d6b;
    color: white;
    font-weight: bold;
    cursor: pointer;
}