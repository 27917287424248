.containerCheckbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.checkbox {
    width: 24px;
    height: 24px;
    border-width: 2px;
    border-color: #ccc;
    border-radius: 4px;
    justify-content: flex-start;
    align-items: center;
    margin-right: 8px;  
}

.checkboxIconChecked {
    font-size: 30px;
    color: black;
}

.checkboxIcon {
    font-size: 30px;
    color: black;
}

.labelTextCheckbox {
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 13px;
    color: #5d5d5d;
}