.formInputErrorEmail {
    border-color: #D32F2F;
}

.formInputEmail {
    height: 47px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #adadad;
    padding: 10px;
    width: 100%;
}
