.viewContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
}

.identifierIcon {
    margin-right: 5px;
}

.identifierText {
    color: #31444D;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-right: 10px;
}

.cardNumberIcon {
    margin-right: 5px;

}

.cardNumberText {
    color: #31444D;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-right: 10px;
}