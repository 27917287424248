.backButton {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.backButtonText {
    font-size: 18px;
    color: #0D4E33;
    display: flex;
    align-items: center;
}

.backButtonText svg {
    margin-right: 5px;
    /* Adiciona um espaço entre o ícone e o texto */
}

.w100 {
    width: 100%;
}

.header {
    padding-top: 35px;
}

.containerRecoverPassword {
    display: flex;
    flex: 1;
    background-color: #fff;
    padding-left: 20px;
    padding-right: 20px;
}

.welcomeText {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 900;
    font-size: 13px;
}

.formLabel {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 11px;
    color: #5d5d5d;
}

.formHelperText {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 11px;
}

.logoContainer {
    align-items: center;
    margin-bottom: 1px;
}

.logo {
    width: 250px;
    height: 52px;
}

.formContainer {
    align-items: center;
}

.formErrorText {
    color: #ad0809;
}

.title {
    font-size: 25px;
    font-weight: bold;
    color: #0d4e33;
    margin-bottom: 30px;
}

.buttonsContainer_ {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.esqueciSenhaView {
    width: 100%;
    text-align: center;
}

.esqueciSenhaText {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.buttonContainer_password {
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.buttonText {
    font-size: 31px;
    font-weight: bold;
    letter-spacing: 0.05px;
    color: #b4e038;
}

.buttonBackground {
    width: 467px;
    height: 48px;
    border-radius: 8px;
    position: absolute;
    opacity: 0.3;
    background-color: #b4e038;
}

.checkboxContainer {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: flex-start;
    width: 100%;
}

.checkboxBox {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    border-width: 1px;
    border-color: #C4C4C4;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
}

.checkboxCheck {
    width: 12px;
    height: 12px;
    background-color: #B4E038;
    border-radius: 2px;
}

.checkboxText {
    font-size: 12px;
    color: #080808;
}

.CPFinputContainer {
    margin-top: 250px;
    width: 100%;
}

.inputLabel {
    font-size: 12px;
    font-weight: 600;
    color: #080808;
    margin-bottom: 10px;
}

.inputBox {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border-width: 1px;
    border-color: #ADADAD;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    color: #080808;
    margin-bottom: 10px;
}

.submitButtonContainer {
    width: 100%;
    margin-top: 30px;
    align-items: center;
}

.submitButton {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background-color: #B4E038;
    justify-content: center;
    align-items: center;
}

.submitButtonText {
    font-size: 16px;
    letter-spacing: 0.05px;
    color: #080808;
}

.termsTextContainer {
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.termsText {
    font-size: 12px;
    color: #080808;
}

.termsLink {
    font-size: 12px;
    text-decoration-line: underline;
    color: #097a5e;
}

.footerText {
    font-size: 10px;
    font-weight: 900;
    text-align: center;
}