.modalLoading .modal-content {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: transparent;
    background-clip: padding-box;
    border: none;
    border-radius: 0.3rem;
    outline: 0;
    box-shadow: none;
}